import React from 'react';
import EditorData from 'components/editor-data/EditorData';
import User from 'components/data/User';
import Translation from 'components/data/Translation';
import TemplateDialog from 'components/ui-base/TemplateDialog';
import Templates, { withCreativeTemplates } from 'components/data/Templates';
import { isAMV2Enabled } from 'components/template-management/utilities';
import AssetGalleryDialog from 'components/assets/AssetGalleryDialog';
import { CREATIVE_TEMPLATE_TYPES, STATIC_ASSET_TYPES, STATIC_ASSETS_V1 } from 'components/template-management/constants';
import Setup from 'components/data/Setup';

import '../styles/main.scss';

/**
 * CreativeBuilderTemplateDialog
 * Shows the template dialog.
 * This is just a wrapper around the generic template dialog.
 */
class CreativeBuilderTemplateDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectors: []
        };
    }

    componentDidMount = () => {
        if (isAMV2Enabled()) {
            this.setupTemplateProps();
            if (this.props.showCreatives && Setup.hasModule('creativeManagement')) this.setupCreativeProps();
        } else {
            const templateItems = this.setupTemplateItems();
            this.setupCategories(templateItems);
        }
    };

    /**
     * Get the total list of available templates in the selection
     * @returns
     */
    setupTemplateItems() {
        const { templateTypes, itemIncludes, templateFilter } = this.props;

        let result = [];

        (templateTypes || CREATIVE_TEMPLATE_TYPES).forEach((item) => {
            let set = [];

            if (Templates.get(item)) {
                set = Templates.get(item);
            } else if (item === 'staticAssetDisplay') {
                set = [STATIC_ASSETS_V1[item]];
            } else if (item === 'staticAssetVideo') {
                set = [STATIC_ASSETS_V1[item]];
            } else if (item === 'staticAssetImage') {
                set = [STATIC_ASSETS_V1[item]];
            } else if (item === 'staticAssetPDF') {
                set = [STATIC_ASSETS_V1[item]];
            } else if (item === 'staticAssetAudio') {
                set = [STATIC_ASSETS_V1[item]];
            }

            Object.keys(set).forEach((key) => {
                result.push(set[key]);
            });
        });

        // Filter items if groupKey is set and has value.
        if (this.props.groupKey) {
            result = result.filter((item) => {
                return item.groupKey === this.props.groupKey;
            });
        }

        // Filter items if itemIncludes is set and has value.
        if (Array.isArray(itemIncludes) && itemIncludes.length > 0) {
            const itemIncludesLowerCase = itemIncludes.map((i) => i.toLowerCase());
            result = result.filter((item) => {
                return itemIncludesLowerCase.includes(item.identifier.toLowerCase());
            });
        }

        // Filter items if itemIncludes is set and has value.
        if (Array.isArray(templateFilter) && templateFilter.length > 0) {
            const templateFilterLowerCase = templateFilter.map((i) => i.toLowerCase());
            result = result.filter((item) => {
                return templateFilterLowerCase.includes(item.identifier.toLowerCase()) || templateFilterLowerCase.includes(item.type.toLowerCase());
            });
        }

        return result;
    }

    /**
     * Set up the different ad types for the categories
     */
    setupCategories = (templateItems) => {
        const categories = [];
        const items = [];
        // Loop through all items loaded from the resourc
        templateItems.forEach((item) => {
            if (item.category && !categories.includes(item.category.toLowerCase())) {
                categories.push(item.category.toLowerCase());
            }

            // This campaign is for another brand. Check whether the templates are linked to a brand. If so, check whether to display them
            let add = true;
            if (
                item.brand &&
                EditorData.getValueFromModel('settings.brand') &&
                EditorData.getValueFromModel('settings.brand') !== '' &&
                item.brand !== EditorData.getValueFromModel('settings.brand')
            ) {
                add = false;
            }

            // I don't have access to this brand, remove template
            if (item.brand && Array.isArray(User.get('brands')) && !User.get('brands').includes(item.brand) && !User.hasRight('multiBrand')) {
                add = false;
            }

            if (add) {
                items.push(item);
            }
        });
        items.sort((a, b) => (a.category > b.category ? 1 : -1));
        this.setState({ categories, items });
    };

    // Setup the template props for the AMV2.
    setupTemplateProps = () => {
        const { templateTypes, itemIncludes, groupKey, onSelectItem } = this.props;
        const { selectors } = this.state;

        // Setup Creative Templates if required.
        const creativeTemplateSubTypes = (() => {
            if (templateTypes) return templateTypes.filter((type) => !STATIC_ASSET_TYPES.includes(type) && type !== 'staticAsset');
            return CREATIVE_TEMPLATE_TYPES.filter((type) => type !== 'staticAsset');
        })();

        const templateProps = (() => {
            if (creativeTemplateSubTypes.length > 0) {
                selectors.push('template');

                const dataFilters = {};
                if (itemIncludes && itemIncludes.length > 0) {
                    dataFilters.identifier = itemIncludes;
                }
                if (groupKey) dataFilters.groupKey = groupKey;
                return { subType: creativeTemplateSubTypes, dataFilters, onSelectItem };
            }
            return null;
        })();

        // Setup Static Assets if required.
        const staticAssetType = (() => {
            if (!templateTypes) return STATIC_ASSET_TYPES;
            return templateTypes.filter((type) => STATIC_ASSET_TYPES.includes(type));
        })();

        const staticAssetProps = (() => {
            if (staticAssetType.length > 0) {
                selectors.push('staticAsset');
                return {
                    staticAssetType,
                    onSelectItem
                };
            }
            return null;
        })();

        this.setState({ templateProps, staticAssetProps });
    };

    // Setup the creative selector props in AMV2.
    setupCreativeProps = () => {
        const { templateTypes, itemIncludes, onSelectItem } = this.props;
        const { selectors } = this.state;

        const subType = (() => {
            if (templateTypes) return templateTypes;
            return null;
        })();

        const dataFilters = {};
        if (itemIncludes && itemIncludes.length > 0) {
            dataFilters.templateIdentifier = itemIncludes;
        }

        selectors.push('creative');

        const creativeProps = { subType, dataFilters, onSelectItem };
        this.setState({ creativeProps });
    };

    render() {
        const { categories, items, templateProps, staticAssetProps, creativeProps, selectors } = this.state;
        const { open, onClose, orderedTemplates, onSelectItem, showCreatives, templateTypes, itemIncludes, assetGalleryDialogProps } = this.props;

        return (
            <div className="creative-builder-template-dialog">
                {items && (
                    <TemplateDialog
                        showCreatives={showCreatives}
                        templateTypes={templateTypes || CREATIVE_TEMPLATE_TYPES}
                        itemIncludes={itemIncludes}
                        isOpen={open}
                        options={items}
                        categories={categories}
                        onClose={onClose}
                        onSelectItem={onSelectItem}
                        assetGalleryDialogProps={assetGalleryDialogProps}
                        title={Translation.get('titles.selectOption', 'common')}
                        key="template-dialog"
                        orderedTemplates={orderedTemplates}
                    />
                )}

                {(templateProps || staticAssetProps) && (
                    // This is the AMV2 based TemplateDialog
                    <AssetGalleryDialog
                        {...assetGalleryDialogProps}
                        title={Translation.get('formflow.JSON.addItem', 'common')}
                        onMutation={assetGalleryDialogProps?.onMutation ?? (() => null)}
                        open={open}
                        fixedHeightPaperScrollPaper
                        onClose={onClose}
                        selectors={selectors}
                        selectorsProps={{ templateProps, staticAssetProps, creativeProps }}
                    />
                )}
            </div>
        );
    }
}

export default withCreativeTemplates(CreativeBuilderTemplateDialog);
