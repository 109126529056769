import React from 'react';
import { isEqual } from 'lodash';
import ComponentStore from 'components/data/ComponentStore';
import GridBlockFill from 'components/ui-components/GridBlockFill';
import DataHelpers from 'components/data/DataHelpers';
import EditorData from 'components/editor-data/EditorData';
import Translation from 'components/data/Translation';
import dynamicImageItems from '../helpers/dynamic-image-items';
import dynamicVideoItems from '../helpers/dynamic-video-items';
import displayAdItems from '../helpers/display-ad-items';
import staticAssetItems from '../helpers/static-asset-items';
import GenericHelpers from '../helpers/generic.js';
import '../styles/main.scss';

/**
 * CreativeBuilderItem
 * Renders an item from the creative builder
 */
class AssetItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            positionsResult: {},
            items: []
        };
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.data, prevProps.data)) {
            this.setState({ items: this.getItems() }, () => {
                if (this.state.items && this.state.items.length > 0) {
                    this.arrangeItems(this.state.items, this.props.data.type);
                }
            });
        }
    }

    /**
     * Arrange items
     * Gets all items by format and displays them
     * @param {array} items - The items that are send
     */
    arrangeItems = (items) => {
        const { subsetActive } = this.props;

        // Check whether we need to rerender because of changes
        if (
            !this.arrangedItems ||
            this.arrangedItemsCount !== items.length ||
            this.arrangedItemsSubset !== subsetActive ||
            this.arrangedItemsLanguage !== this.props.language
        ) {
            // Set the active object for next rerender check
            this.arrangedItems = true;
            this.arrangedItemsLanguage = this.props.language;
            this.arrangedItemsSubset = subsetActive;
            this.arrangedItemsCount = items.length;

            // Loop through width
            const formatsByObject = {};
            let maxWidth = 0;

            items.forEach((item) => {
                formatsByObject[item.key] = { width: item.width, height: item.height };
                if (item.width + 16 > maxWidth) {
                    maxWidth = item.width + 20;
                }
            });

            let windowWidth = window.innerWidth - 540;
            if (this.props.fullWindow) {
                windowWidth = document.querySelector('.creative-builder__list').clientWidth;
            }

            const width = maxWidth > windowWidth ? maxWidth : windowWidth;
            this.positionsResult = GridBlockFill.returnPositions(width, formatsByObject, 16, 32);
        }
    };

    /**
     * Select item
     * @param {string} format - The selected format as a string.
     */
    onSelectFormat = (format) => {
        const { selectedFormats = [] } = this.props;
        let selectedFormatsList = DataHelpers.clone(selectedFormats, false);

        if (selectedFormatsList.includes(format)) {
            selectedFormatsList = selectedFormats.filter((item) => format !== item);
        } else {
            selectedFormatsList.push(format);
        }

        ComponentStore.setData('CreativeEditor', { selectedFormats: selectedFormatsList });
    };

    /**
     * Edit Background button pressed
     * Opens the dialog to crop your image
     * @param {string} format - The format as a string
     * @param {string} width The width of the output images
     * @param {string} height The height of the output image
     * @param {string} originalModel The full model path
     * @param {string} overwriteModel The overwrite model path
     */
    onEditBackground = (format, width, height, originalModel, overwriteModel) => {
        ComponentStore.setData('CreativeEditor', {
            editBackgroundOpen: true,
            editBackgroundFormat: format,
            editBackgroundWidth: width,
            editBackgroundHeight: height,
            originalModel,
            overwriteModel
        });
    };

    /**
     * Scale a format
     * This saved the format and rerenders
     * @param {string} format
     * @param {string} scale
     */
    onChangeScale = (type, format, scale) => {
        const { maxWidthScale, maxHeightScale } = this.props;
        this.arrangedItems = false;
        GenericHelpers.updateScale(type, format, scale, maxWidthScale, maxHeightScale);
        this.setState({ rerender: Math.random() });
    };

    /**
     * Remove item in case we have static uploads
     * @param {string} format
     * @param {string} scale
     */
    onRemoveItem = (uuid) => {
        const { dataModel, subsetActive } = this.props;

        // Add item to array
        let items = EditorData.getValueFromModel(dataModel + '.data.' + subsetActive + '.assetData.items');
        items = DataHelpers.clone(items, false);
        items = items.filter((item) => item.uuid !== uuid);

        // Set model
        EditorData.setModel(dataModel + '.data.' + subsetActive + '.assetData.items', items);
    };

    /**
     * Get list of items of the right type
     */
    getItems() {
        const { data } = this.props;

        // Get all items
        let items;
        if (data.type === 'dynamicVideo' || data.type === 'dynamicAfterEffects') {
            items = dynamicVideoItems(this.props, this.onChangeScale, this.onSelectFormat);
        } else if (data.type === 'displayAd' || data.type === 'displayAdDesigned') {
            items = displayAdItems(this.props, this.onChangeScale, this.onSelectFormat, this.onEditBackground);
        } else if (
            data.type === 'dynamicImage' ||
            data.type === 'dynamicInDesign' ||
            data.type === 'dynamicPDF' ||
            data.type === 'dynamicImageDesigned' ||
            data.type === 'dynamicVideoDesigned' ||
            data.type === 'dynamicPDFDesigned'
        ) {
            items = dynamicImageItems(this.props, this.onChangeScale, this.onSelectFormat, this.onEditBackground);
        } else if (
            data.type === 'staticAssetDisplay' ||
            data.type === 'staticAssetImage' ||
            data.type === 'staticAssetPDF' ||
            data.type === 'staticAssetVideo' ||
            data.type === 'staticAssetAudio'
        ) {
            items = staticAssetItems(this.props, this.onChangeScale, this.onRemoveItem);
        }
        return items;
    }

    /**
     * Render the component
     */
    render() {
        const { data } = this.props;

        // Get all items
        const items = this.getItems();

        // Get view type
        let displayType;
        if (!items || items.length === 0) {
            displayType = 'no-results';
        } else if (items.length > 1) {
            displayType = 'full-grid';
        } else {
            displayType = 'single-grid';
        }

        // No results
        if (displayType === 'no-results') {
            return <div className="creative-builder-item__no-results">{Translation.get('placeholders.noCreatives', 'campaigns')}</div>;
        }

        // Full grid
        if (displayType === 'full-grid') {
            this.arrangeItems(items, data.type);
            return (
                <div className="creative-builder-item__full-grid" style={{ height: this.positionsResult.height }}>
                    {items.map(
                        (item) =>
                            this.positionsResult.positions[item.key] && (
                                <div
                                    className={'creative-builder-item__full-grid__item ' + item.className}
                                    key={item.key}
                                    style={{
                                        left: this.positionsResult.positions[item.key].x,
                                        top: this.positionsResult.positions[item.key].y
                                    }}>
                                    {item.component}
                                </div>
                            )
                    )}
                </div>
            );
        }
        // Single grid
        if (displayType === 'single-grid') {
            this.arrangeItems(items, data.type);
            return (
                <div className="creative-builder-item__single-grid">
                    {items.map((item) => (
                        <div className={item.className} key={item.key}>
                            {item.component}
                        </div>
                    ))}
                </div>
            );
        }
    }
}

export default AssetItem;
