import React from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import '../styles/main.scss';

const CreativeBuilderTypeIcon = ({ type, className }) => {
    if (type === 'display' || type === 'video' || type === 'social') {
        let icon = '';

        switch (type) {
            case 'display':
                icon = 'web';
                break;
            case 'video':
                icon = 'movie';
                break;
            case 'social':
                icon = 'thumbs_up_down';
                break;
            default:
                icon = '';
                break;
        }

        return <Icon>{icon}</Icon>;
    }

    return <div className={classNames('creative-builder-type-icon', 'creative-builder-type-icon--' + type, className)}></div>;
};

export default CreativeBuilderTypeIcon;
