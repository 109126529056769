import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import CropIcon from '@mui/icons-material/Crop';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Icon from 'components/ui-components-v2/Icon';
import DynamicAsset from 'components/assets/DynamicAsset';
import EditorData from 'components/editor-data/EditorData';
import Checkbox from 'components/ui-components-v2/Checkbox';
import DynamicVideo from 'components/channels/video/DynamicVideo';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import DynamicPDF from 'components/channels/pdf/DynamicPDF';
import PreviewFrameWarnings from './warnings';
import TemplateSize from './template-size';
import '../styles/main.scss';

/**
 * The previewframe
 * This is a container for display ads, that allows to overwrite, and handles the overwrites.
 */
class PreviewFrame extends React.Component {
    static propTypes = {
        /** Width of the preview */
        width: PropTypes.number,
        /** Height of the preview */
        height: PropTypes.number,
        /** Title of the preview */
        title: PropTypes.string,
        /** URL of the preview */
        url: PropTypes.string,
        /** Format */
        format: PropTypes.string,
        /** Data of the preview */
        data: PropTypes.object,
        /** Type of the preview */
        type: PropTypes.string,
        /** Can overwrite the item */
        canOverwrite: PropTypes.bool,
        /** Can edit the background image of this item */
        canEditBackground: PropTypes.bool,
        /** Can edit the background image of this item */
        canSelect: PropTypes.bool,
        /** Can edit the background image of this item */
        isSelected: PropTypes.bool,
        /** The grid size in pixels if available */
        gridSize: PropTypes.number,
        /** Whether we want to show the grid */
        gridShow: PropTypes.bool,
        /** Whether this is a preview */
        isPreview: PropTypes.bool,
        /** Load in the view */
        loadInView: PropTypes.bool,
        /** Comments open */
        onCommentsOpen: PropTypes.any,
        /** Warnings to show */
        messages: PropTypes.any,
        /** Remove item from list */
        onRemoveItem: PropTypes.any,
        /** Unique uuid for this item */
        uuid: PropTypes.any,
        /** Template data **/
        template: PropTypes.any
    };

    static defaultProps = {
        width: 300,
        height: 250,
        title: '',
        url: '',
        data: {},
        format: 'main',
        canEdit: false,
        canOverwrite: false,
        gridSize: 10,
        gridShow: false
    };

    constructor(props) {
        super(props);

        // Make sure the in view function works
        this.ref = React.createRef();
        if (props.loadInView) {
            this.interval = setInterval(this.checkWhetherInView, 400);
        }

        this.state = {
            iframeKey: 0,
            visible: false,
            confirmResetOverwrites: false
        };
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    /**
     * Reload an iframe
     */
    handleReload = () => {
        this.setState({ iframeKey: this.state.iframeKey + 1, percentageEdit: false });
    };

    /**
     * Get full model path to update or reset value
     * @returns string full model path
     */
    getFullUpdateModel = () => {
        const { assetUpdateModel, assetUpdateModelFull, frameNr, type } = this.props;

        let fullUpdateModel = '';

        // We have a display ad. We use frame number here
        if (type === 'display') {
            fullUpdateModel = assetUpdateModel + '.frames.frame' + frameNr;
            if (assetUpdateModelFull) {
                fullUpdateModel = assetUpdateModelFull + '.frames.frame' + frameNr;
            }
        }
        // Other types, we use the generic format
        else {
            fullUpdateModel = assetUpdateModelFull;
        }

        return fullUpdateModel;
    };

    editBackground = () => {
        const {
            assetUpdateModel,
            assetUpdateModelFull,
            frameNr,
            type,
            onEditBackground,
            backgroundModel,
            backgroundSize,
            backgroundImageMultiplier = 1,
            format
        } = this.props;

        const itemModel = backgroundModel || 'backgroundImage';

        let originalModel = assetUpdateModel + '.' + itemModel;
        let overwriteModel = assetUpdateModelFull + '.' + itemModel;

        if (type === 'display') {
            let frameAddition = '';
            if (frameNr) frameAddition = `.frames.frame${frameNr}`;
            else frameAddition = '.frames.frame';

            originalModel = assetUpdateModel + frameAddition + '.' + itemModel;
            overwriteModel = assetUpdateModelFull + frameAddition + '.' + itemModel;
        }

        onEditBackground(
            format,
            backgroundSize.width * backgroundImageMultiplier,
            backgroundSize.height * backgroundImageMultiplier,
            originalModel,
            overwriteModel
        );
    };

    /**
     * Reset all the overwrites
     */
    resetOverwrites = () => {
        const { isSelected, onSelectItem } = this.props;
        EditorData.setModel(this.getFullUpdateModel(), {});

        // Uncheck the checkmark
        if (isSelected) {
            onSelectItem();
        }
        this.setState({ confirmResetOverwrites: false });
    };

    /**
     * Toggle the percentage view
     */
    togglePercentage = () => {
        this.setState({ percentageEdit: !this.state.percentageEdit });
    };

    /**
     * Check whether the iframe is in view
     */
    checkWhetherInView = () => {
        if (this.ref && this.ref.current) {
            const top = this.ref.current.getBoundingClientRect().top;
            const bottom = this.ref.current.getBoundingClientRect().bottom;

            const visible = top <= window.innerHeight && bottom > 0;
            if (visible !== this.state.visible) {
                this.setState({ visible });
            }
        }
    };

    render() {
        const {
            type,
            showTemplateSize,
            url,
            data,
            width,
            height,
            displayWidth = this.props.width,
            displayHeight = this.props.height,
            bottomSpacing,
            scale,
            title,
            style,
            isSelected = false,
            onSelectItem,
            onChangeScale,
            assetUpdateModel = '',
            canOverwrite = true,
            hasOverwrites = false,
            canEditBackground = true,
            onEditBackground,
            onRemoveItem,
            canSelect = true,
            viewType,
            onCommentsOpen,
            format,
            frameNr,
            gridSize,
            showGrid,
            videoData,
            isPreview,
            loadInView,
            commentsCategoryKey = '',
            commentCount = 0,
            messages,
            uuid,
            template,
            previewDataModel
        } = this.props;

        const { iframeKey, percentageEdit, visible, confirmResetOverwrites } = this.state;

        const showBackgroundButton = viewType !== 'comments' && canEditBackground && onEditBackground;
        const showResetOverwritesButton = viewType !== 'comments' && !isPreview && canOverwrite && hasOverwrites;
        const showPlayButton = (type === 'display' && (!frameNr || frameNr === 0)) || type === 'staticAssetDisplay';
        const showCommentsButton = viewType === 'comments';
        const showRemoveButton = onRemoveItem && viewType !== 'comments' && !isPreview;
        const smallView = showTemplateSize ? displayWidth <= 340 : displayWidth < 200;
        const isSelectedForComments = commentsCategoryKey.includes(format);

        return (
            <React.Fragment>
                <div
                    className={'preview-frame' + (isSelected ? ' preview-frame--selected' : '') + (smallView ? ' preview-frame--small' : '')}
                    style={style}
                    ref={this.ref}>
                    <div className="preview-frame__info" style={{ width: displayWidth }}>
                        <div className={'preview-frame__info__block' + (smallView ? ' preview-frame__info__block--small' : '')}>
                            {viewType !== 'comments' && canSelect && (
                                <React.Fragment>
                                    <Tooltip title="Select this ad for overwrites">
                                        <FormControlLabel
                                            className="preview-frame__info__block__title"
                                            control={<Checkbox size="small" checked={isSelected} onClick={onSelectItem} data-tour-selector="formatFocus" />}
                                            label={title}
                                        />
                                    </Tooltip>
                                </React.Fragment>
                            )}
                            {showTemplateSize && <TemplateSize data={data} />}
                        </div>
                        <div className="preview-frame__info__actions">
                            <div className={'preview-frame__info__scale ' + (percentageEdit ? 'preview-frame__info__scale--edit' : '')}>
                                <div className="preview-frame__info__scale__min" onClick={() => onChangeScale(type, format, parseFloat(scale) - 0.05)}>
                                    -
                                </div>
                                <div className="preview-frame__info__scale__percent" onClick={this.togglePercentage}>
                                    {Math.round(scale * 100)}%
                                </div>
                                <div className="preview-frame__info__scale__plus" onClick={() => onChangeScale(type, format, parseFloat(scale) + 0.05)}>
                                    +
                                </div>
                            </div>

                            {showBackgroundButton && (
                                <Tooltip title="Crop the background image">
                                    <div className="preview-frame__info__actions__action">
                                        <CropIcon fontSize="small" onClick={() => this.editBackground()} />
                                    </div>
                                </Tooltip>
                            )}
                            {showResetOverwritesButton && (
                                <Tooltip title="Reset overwrites of this ad">
                                    <div className="preview-frame__info__actions__action">
                                        <RotateLeftIcon fontSize="small" onClick={() => this.setState({ confirmResetOverwrites: true })} />
                                    </div>
                                </Tooltip>
                            )}
                            {showRemoveButton && (
                                <Tooltip title="Remove this item">
                                    <Icon className="preview-frame__info__actions__action" fontSize="small" onClick={() => onRemoveItem(uuid)}>
                                        delete
                                    </Icon>
                                </Tooltip>
                            )}
                            {showPlayButton && (
                                <Tooltip title="Reload the ad">
                                    <Icon className="preview-frame__info__actions__action" fontSize="small" onClick={this.handleReload}>
                                        play_arrow
                                    </Icon>
                                </Tooltip>
                            )}
                            {showCommentsButton && commentCount === 0 && (
                                <React.Fragment>
                                    {isSelectedForComments ? (
                                        <Tooltip title="Close this format">
                                            <Icon className="preview-frame__info__actions__action" fontSize="small" onClick={() => onCommentsOpen(format)}>
                                                close
                                            </Icon>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Comment on this format">
                                            <Icon className="preview-frame__info__actions__action" fontSize="small" onClick={() => onCommentsOpen(format)}>
                                                message
                                            </Icon>
                                        </Tooltip>
                                    )}
                                </React.Fragment>
                            )}
                            {showCommentsButton && commentCount > 0 && (
                                <React.Fragment>
                                    {isSelectedForComments ? (
                                        <Tooltip title="Close this format">
                                            <div className="preview-frame__comment-container">
                                                <Icon className="preview-frame__info__actions__action" fontSize="small" onClick={() => onCommentsOpen(format)}>
                                                    close
                                                </Icon>
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Comment on this format">
                                            <div className="preview-frame__comment-container">
                                                <Icon className="preview-frame__info__actions__action" fontSize="small" onClick={() => onCommentsOpen(format)}>
                                                    message
                                                </Icon>
                                            </div>
                                        </Tooltip>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div className="preview-frame__iframe-wrapper" style={{ width: displayWidth, height: displayHeight }}>
                        {(!loadInView || visible) && (
                            <React.Fragment>
                                {messages && <PreviewFrameWarnings messages={messages} />}

                                {showGrid && <div className="preview-frame__grid" style={{ 'background-size': gridSize + 'px ' + gridSize + 'px' }}></div>}

                                {type === 'display' && (
                                    <DynamicAsset
                                        type={'iframe'}
                                        key={'iframeKey' + iframeKey}
                                        width={displayWidth}
                                        height={displayHeight}
                                        frameWidth={width}
                                        frameHeight={height}
                                        bottomSpacing={bottomSpacing}
                                        url={url}
                                        data={data}
                                        assetUpdateModel={assetUpdateModel}
                                    />
                                )}

                                {(type === 'dynamicImage' || type === 'dynamicPDFDesigned') && (
                                    <DynamicAsset
                                        type={type}
                                        width={displayWidth}
                                        height={displayHeight}
                                        frameWidth={width}
                                        frameHeight={height}
                                        bottomSpacing={bottomSpacing}
                                        url={url}
                                        data={data}
                                        assetUpdateModel={assetUpdateModel}
                                    />
                                )}

                                {type === 'staticAssetImage' && <DynamicAsset type="image" width={displayWidth} height={displayHeight} url={url} />}

                                {type === 'staticAssetPDF' && <DynamicAsset type="image" width={displayWidth} height={displayHeight} url={url} />}

                                {type === 'staticAssetDisplay' && (
                                    <DynamicAsset
                                        type="iframe"
                                        key={'iframeKey' + iframeKey}
                                        width={displayWidth}
                                        height={displayHeight}
                                        frameWidth={width}
                                        frameHeight={height}
                                        url={url}
                                    />
                                )}

                                {type === 'staticAssetVideo' && <DynamicAsset type="video" width={displayWidth} height={displayHeight} url={url} />}

                                {type === 'staticAssetAudio' && <DynamicAsset type="audio" width={displayWidth} height={displayHeight} url={url} />}

                                {(type === 'dynamicVideo' || type === 'dynamicAfterEffects') && (
                                    <DynamicVideo width={width} height={height} {...videoData} template={template} />
                                )}

                                {type === 'dynamicInDesign' && (
                                    <DynamicPDF
                                        displayWidth={displayWidth}
                                        displayHeight={displayHeight}
                                        width={width}
                                        height={height}
                                        template={template}
                                        format={format}
                                        previewDataModel={previewDataModel}
                                        data={data}
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>

                <ConfirmDialog
                    open={confirmResetOverwrites}
                    title={'Reset overwrites of this ad?'}
                    description={'Are you sure you want to reset the overwrites of this ad?'}
                    onConfirm={() => this.resetOverwrites()}
                    onClose={() => this.setState({ confirmResetOverwrites: false })}
                />
            </React.Fragment>
        );
    }
}

export default PreviewFrame;
