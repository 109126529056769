import React from 'react';
import ButtonGroup from 'components/ui-components-v2/ButtonGroup';
import Button from 'components/ui-components-v2/Button';
import EditorData from 'components/editor-data/EditorData';
import cloneDeep from 'helpers/cloneDeep';

/**
 * ReviewButtons
 * This shows review buttons at the top of the view
 */
class ReviewButtons extends React.Component {
    static defaultProps = {
        item: {},
        model: null
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    /**
     *  Set status of the item in the list
     * @param {*} status
     */
    setStatus = (status) => {
        const { item, model } = this.props;
        const originalData = cloneDeep(EditorData.getValueFromModel(model));
        originalData.map((itemData) => {
            if (itemData.uuid === item.uuid) {
                itemData.reviewStatus = status;
            }
        });
        EditorData.setModel(model, originalData);
    };

    render() {
        const { item, canReview } = this.props;
        return (
            <div>
                <ButtonGroup size="small" aria-label="small button group">
                    <Button
                        onClick={() => this.setStatus('inreview')}
                        key="inreview"
                        color="info"
                        variant={item.reviewStatus && item.reviewStatus === 'inreview' ? 'contained' : undefined}>
                        Review
                    </Button>

                    {canReview && (
                        <Button
                            onClick={() => this.setStatus('approved')}
                            key="approved"
                            color="success"
                            variant={item.reviewStatus && item.reviewStatus === 'approved' ? 'contained' : undefined}>
                            Approved
                        </Button>
                    )}
                    {canReview && (
                        <Button
                            onClick={() => this.setStatus('declined')}
                            key="declined"
                            color="error"
                            variant={item.reviewStatus && item.reviewStatus === 'declined' ? 'contained' : undefined}>
                            Declined
                        </Button>
                    )}
                </ButtonGroup>
            </div>
        );
    }
}

export default ReviewButtons;
