import React from 'react';
import { AssetToCreate } from 'types/asset.type';
import Setup from 'components/data/Setup';
import { isAMV2Enabled } from 'components/template-management/utilities';
import CreativeManagementHelpers from 'components/data/CreativeManagementHelpers';
import User from 'components/data/User';
import { CreativeSubType } from 'components/creative-management-v2/types/creative-sub-type.type';
import { CreativeData, TDCreativeData } from 'components/creative-management-v2/types/creative-management.type';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { CREATIVE_MANAGEMENT_CONFIG } from 'components/creative-management-v2/constants';

interface Props {
    item: {
        identifier: string;
        type: CreativeSubType;
        uuid: string;
    };
    data: CreativeData | TDCreativeData;
}

const CreativeBuilderBlockExportToCreativeManagement = ({ item, data }: Props) => {
    const copyAsset = async () => {
        const title = `${data.title} - copy`;
        const duplicateAsset: AssetToCreate<unknown, unknown> & { subType: CreativeSubType } = {
            title,
            type: 'creative',
            subType: item.type,
            data: { ...(data as object), title },
            status: 'draft'
        };
        const response = await AssetManagementService.createAsset(duplicateAsset);
        if (response) {
            ComponentStoreHelpers.remove(CREATIVE_MANAGEMENT_CONFIG.storeName); // Clear the store to force a reload of the creatives.
            SnackbarUtils.success(Translation.get('snackbar.exportedToCreatives', 'creative-management-v2'));
        }
    };

    if (!Setup.hasModule('creativeManagement')) return null;

    // AMV2 version.
    if (isAMV2Enabled('creative')) {
        return <MenuItem onClick={copyAsset}>{Translation.get('labels.exportToCreativeManagement', 'creative-management')}</MenuItem>;
    }

    // Old asset management scenario.
    if (
        User.hasRight('assetLibraryAdd') &&
        (CreativeManagementHelpers.htmlTemplateBasedCreatives.includes(item.type) ||
            //Looking in data?.channelSetup?.type is a fix because at first the item.type of a social channel item  from CM was not correctly added to a new builder item
            CreativeManagementHelpers.htmlTemplateBasedCreatives.includes(data?.channelSetup?.type || ''))
    ) {
        return (
            <MenuItem onClick={() => CreativeManagementHelpers.importCreative(item, data)}>
                {Translation.get('labels.exportToCreativeManagement', 'creative-management')}
            </MenuItem>
        );
    }

    return null;
};

export default CreativeBuilderBlockExportToCreativeManagement;
