import React from 'react';
import Sidebar from 'components/ui-base/Sidebar';
import Columns from 'components/ui-base/Columns';
import EditorData from 'components/editor-data/EditorData';
import CreativeBuilderTypeIcon from 'components/creative-builder/CreativeBuilderTypeIcon';
import CreativeBuilderHelpers from 'components/creative-builder/CreativeBuilderHelpers';
import Block from './block';

import '../styles/main.scss';

/**
 * Creative Builder Preview
 * Shows all the items as a preview
 */
export default class CreativeBuilderPreview extends React.Component {
    static defaultProps = {
        value: [],
        displayType: 'list'
    };

    constructor(props) {
        super(props);
        const { value, dataModel, partial, partialList } = props;

        const list = (() => {
            const fullList = [];

            // Build the full list of items.
            if (value && Array.isArray(props.value) && value.length) {
                props.value.forEach((item) => {
                    const data = EditorData.getValueFromModel(dataModel + '.' + item.uuid);
                    const title = data && data.title ? data.title : item.identifier;
                    fullList.push({
                        ...item,
                        id: item.uuid,
                        title,
                        typeIcon: <CreativeBuilderTypeIcon type={CreativeBuilderHelpers.getTypeIcon(data)} className="sidebar-list-item__type--usefilter" />
                    });
                });
            }

            if (partial && partialList && partialList.length) {
                return fullList.filter((item) => partialList.includes(item.id));
            }
            return fullList;
        })();

        const currentItem = list.length ? list[0] : {};
        this.state = { currentItem, list };
    }

    handleSelectItem = (uuid) => {
        const { value, dataModel, onSetActiveTitle } = this.props;
        const currentItem = value.find((item) => item.uuid === uuid);
        const currentTitle = EditorData.getValueFromModel(dataModel + '.' + currentItem.uuid + '.title');
        this.setState({ currentItem }, () => onSetActiveTitle(currentTitle));
    };

    render() {
        const { dataModel, language, defaultFullSize, displayType } = this.props;
        const { currentItem, list } = this.state;

        if (!list.length) return <div>No creatives found</div>;

        return (
            <React.Fragment>
                {displayType === 'list' ? (
                    <Sidebar list={list} activeItem={currentItem && currentItem.uuid} onSelect={this.handleSelectItem} docked>
                        <Block
                            key={'creative-builder-block__' + currentItem.uuid}
                            item={currentItem}
                            dataModel={dataModel}
                            language={language}
                            defaultFullSize={defaultFullSize}
                        />
                    </Sidebar>
                ) : (
                    <Columns count={3}>
                        {list.map((item) => (
                            <Block
                                key={'creative-builder-block__' + item.uuid}
                                item={item}
                                dataModel={dataModel}
                                language={language}
                                defaultFullSize={defaultFullSize}
                            />
                        ))}
                    </Columns>
                )}
            </React.Fragment>
        );
    }
}
