// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/facebook.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../images/instagram.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../images/linkedin.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../images/pinterest.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../images/x.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../images/youtube.svg");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../images/snapchat.svg");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../images/tiktok.svg");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../images/video.svg");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../images/display.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
exports.push([module.id, "/* Units are deprecated, use the $s* variables instead */\n/* Sizes using rem. This is based on a default 16px */\n/* This is to provide better control and consistency in managing stacking components */\n/* Custom sizes */\n.creative-builder-type-icon {\n  width: 20px;\n  min-width: 20px;\n  height: 20px;\n  background-size: 100% 100%;\n}\n.creative-builder-type-icon--facebook {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.creative-builder-type-icon--instagram {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.creative-builder-type-icon--linkedin {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.creative-builder-type-icon--pinterest {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n.creative-builder-type-icon--twitter {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n.creative-builder-type-icon--youtube {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}\n.creative-builder-type-icon--snapchat {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ");\n}\n.creative-builder-type-icon--tiktok {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ");\n}\n.creative-builder-type-icon--video {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ");\n}\n.creative-builder-type-icon--display {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ");\n}", ""]);
// Exports
module.exports = exports;
