import DataHelpers from 'components/data/DataHelpers';

export default class {
    /**
     * Get the formats of the creative item that should be displayed
     * @param {*} data
     * @param {*} specificFormat
     */
    static getFormats(data, specificFormat) {
        // Get formats
        let formatsData = DataHelpers.getValue(data, 'assetSetup.settings.formats', []);

        // If a specificFormat is giving, take it from settings.formats
        if (specificFormat) {
            const specificFormatData = formatsData.find((f) => f.format === specificFormat);
            if (specificFormatData) {
                formatsData = [specificFormatData];
            }
        }
        if (!Array.isArray(formatsData)) formatsData = [];

        return formatsData;
    }

    /**
     * Get scale of an item
     * This looks at my localstorage, the type of asset and other variables to
     * determine which size to show the asset in.
     * @param {*} type The type of creative
     * @param {*} format The format of the creative
     * @param {*} width The width of origin
     * @param {*} height The height of origin
     * @param {*} maxWidthScale The maximum width the item can have
     * @param {*} maxHeightScale The maximum height the item can have
     * @param {*} numberOfItems
     * @param {string[]} defaultFullSize The formats in this array will be shown on 100% by default. If the scale is changed manually, it will remember that in localStorage, like usual
     */
    static getScale(type, format, width, height, maxWidthScale, maxHeightScale, numberOfItems = 5, defaultFullSize = []) {
        let existingFormats = localStorage.getItem('CreativeBuilderItem_displayFormats');
        if (existingFormats) {
            existingFormats = JSON.parse(existingFormats);
        }

        // Set the max size
        let maxWidth = 600;
        let maxHeight = 400;

        if (type === 'display' || type === 'staticAssetDisplay' || type === 'dynamicPDFDesigned') {
            maxWidth = 800;
            maxHeight = 500;
        }

        if (type === 'dynamicVideo' || type === 'staticAssetVideo' || type === 'dynamicVideoDesigned' || type === 'dynamicAfterEffects') {
            maxHeight = 600;
        }

        if (type === 'dynamicInDesign') {
            maxWidth = 1000;
            maxHeight = 1000;
        }

        if (numberOfItems === 1) {
            maxWidth = 1000;
            maxHeight = 1000;
        }

        // Overwrite the max height/width if a prop is given
        if (maxWidthScale) maxWidth = maxWidthScale;
        if (maxHeightScale) maxHeight = maxHeightScale;

        // Return the scale
        const formatIdentifier = `${type}_${format}${maxWidthScale || maxHeightScale ? `${maxWidthScale}${maxHeightScale}` : ''}`;

        if (existingFormats && existingFormats[formatIdentifier]) {
            return existingFormats[formatIdentifier];
        } else if (defaultFullSize.includes(format) || defaultFullSize.includes(`${width}x${height}`) || defaultFullSize.includes('all')) {
            return 1;
        } else if (width > height && width > maxWidth) {
            return (Math.round((maxWidth / width) * 20) / 20).toFixed(2);
        } else if (height > maxHeight) {
            return (Math.round((maxHeight / height) * 20) / 20).toFixed(2);
        } else {
            return 1;
        }
    }

    /**
     * Update the existing scale via the UI
     * @param {*} type The type of creative
     * @param {*} format The format of the creative
     * @param {*} scale The new scale to set
     * @param {*} maxWidthScale The maximum width the item can have
     * @param {*} maxHeightScale The maximum height the item can have
     */
    static updateScale(type, format, scale, maxWidthScale, maxHeightScale) {
        let existingFormats = localStorage.getItem('CreativeBuilderItem_displayFormats');
        if (existingFormats) {
            existingFormats = JSON.parse(existingFormats);
        } else {
            existingFormats = {};
        }

        existingFormats[`${type}_${format}${maxWidthScale || maxHeightScale ? `${maxWidthScale}${maxHeightScale}` : ''}`] = scale;
        localStorage.setItem('CreativeBuilderItem_displayFormats', JSON.stringify(existingFormats));
    }
}
