import React from 'react';
import classNames from 'classnames';
import EditorData from 'components/editor-data/EditorData';
import CreativeBuilderItem from 'components/creative-builder/CreativeBuilderItem';
import CreativeBuilderTypeIcon from 'components/creative-builder/CreativeBuilderTypeIcon';
import CreativeBuilderHelpers from 'components/creative-builder/CreativeBuilderHelpers';
import EditorSubsets from 'components/editor-base/EditorSubsets';

import '../styles/block.scss';

/**
 * Creative Builder Preview Block
 * Shows an individual block
 */
class Block extends React.Component {
    static defaultProps = {
        value: []
    };

    constructor(props) {
        super(props);

        this.ref = React.createRef();
        this.interval = setInterval(this.checkWhetherInView, 400);

        this.state = {
            value: props.value ? props.value : [],
            subsetActive: 'main',
            isSocial: props.item.type === 'social' || props.item.type === 'socialChannelItem'
        };

        // Find a subset
        try {
            const data = EditorData.getValueFromModel(props.dataModel + '.' + props.item.uuid);
            if (data && data.subsets) {
                this.state.subsetActive = data.subsets[0];
            }
        } catch (e) {}
    }

    /**
     * Set a subset to active
     * @param {string} subsetItem - The subset that we want to activate. Sets local item.
     */
    onSetSubsetActive = (subsetItem) => {
        this.setState({ subsetActive: subsetItem });
    };

    render() {
        const { dataModel, language, item, defaultFullSize } = this.props;
        const { subsetActive, isSocial } = this.state;

        const data = EditorData.getValueFromModel(dataModel + '.' + item.uuid);

        return (
            <div className={classNames('creative-builder-preview-block', 'creative-builder__list')} ref={this.ref}>
                <div className={classNames({ 'creative-builder-preview-block__chrome': isSocial })}>
                    {isSocial && (
                        <div className="creative-builder-preview-block__title">
                            <CreativeBuilderTypeIcon type={CreativeBuilderHelpers.getTypeIcon(data)} className="creative-builder-preview-block__icon" />
                            {data.title ? data.title : item.identifier}
                        </div>
                    )}
                    {data.subsets && data.subsets.length > 1 && (
                        <div className={classNames('creative-builder-preview-block__subsets', { 'creative-builder-preview-block__subsets--social': isSocial })}>
                            <EditorSubsets items={data.subsets} canEdit={false} selected={subsetActive} onSetSubsetActive={this.onSetSubsetActive} />
                        </div>
                    )}
                    <CreativeBuilderItem
                        data={data}
                        dataModel={dataModel + '.' + item.uuid}
                        item={item}
                        language={language}
                        subsetActive={subsetActive}
                        waitForLoading={false}
                        isPreview={true}
                        fullWindow={true}
                        defaultFullSize={defaultFullSize}
                        className={classNames({ 'creative-builder-preview-block__chrome-item': isSocial })}
                    />
                </div>
            </div>
        );
    }
}

export default Block;
