import React, { useEffect, useState } from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import classNames from 'classnames';
import Translation from 'components/data/Translation';
import LWFiles from 'components/data/Files';
import TemplateSizeDialog from './template-size-dialog';
import '../styles/template-size.scss';

interface Props {
    data: unknown;
}

const sizeLimit = 150000; // 150kb

const TemplateSize = ({ data }: Props) => {
    const [showDialog, setShowDialog] = useState(false);
    const [templateSize, setTemplateSize] = useState<number | null>(null);

    useEffect(() => {
        setTemplateSize(null);
    }, [data]);

    return (
        <>
            {showDialog && <TemplateSizeDialog data={data} onReceiveSize={setTemplateSize} onClose={() => setShowDialog(false)} />}
            <div onClick={() => setShowDialog(true)}>
                {templateSize ? (
                    <Tooltip title={Translation.get('general.info.estimatedSize', 'template-designer')}>
                        <div
                            className={classNames('preview-frame__size', {
                                'preview-frame__size--good': templateSize < sizeLimit,
                                'preview-frame__size--bad': templateSize >= sizeLimit
                            })}>
                            {LWFiles.humanReadableSize((templateSize / 1000) * 1024)} <Icon>bar_chart</Icon>
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip title={Translation.get('general.info.calculateSizeFrame', 'template-designer')}>
                        <div className="preview-frame__size">
                            {Translation.get('general.info.calculateSize', 'template-designer')} <Icon>sync</Icon>
                        </div>
                    </Tooltip>
                )}
            </div>
        </>
    );
};

export default TemplateSize;
