import React from 'react';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import EditorData from 'components/editor-data/EditorData';
import Templates from 'components/data/Templates';
import DataHelpers from 'components/data/DataHelpers';
import TemplateHelpers from 'components/data/TemplateHelpers';
import PreviewFrame from '../../PreviewFrame';
import GenericHelpers from './generic.js';

import '../styles/main.scss';

/**
 * Creates the dynamic ad items
 */
export default function (props, onChangeScale, onSelectFormat, onEditBackground) {
    const {
        data,
        subsetActive,
        dataModel,
        isPreview = true,
        frameNr,
        language,
        editor,
        selectedFormats = [],
        showGrid = false,
        loadInView = false,
        specificFormat,
        viewType,
        commentsCountsByCategory = {},
        maxWidthScale,
        maxHeightScale,
        commentsCategoryKey,
        onCommentsOpen,
        defaultFullSize
    } = props;
    let { canEditBackground = false } = props;

    // Get formats
    const formatsData = GenericHelpers.getFormats(data, specificFormat);

    let isSelectable = true;

    if (data.assetSetup && data.assetSetup.settings && data.assetSetup.settings.availableFormats && data.assetSetup.settings.availableFormats.length < 2) {
        isSelectable = false;
    }

    // Fetch initial data
    let url = DataHelpers.getValue(data, 'assetSetup.settings.url', '');
    const gridSize = DataHelpers.getValue(data, 'assetSetup.settings.gridSize', 8);
    const backgroundSizes = DataHelpers.getValue(data, 'assetSetup.settings.backgroundSizes');
    let positionByLanguage = DataHelpers.getValue(data, 'assetSetup.settings.positionByLanguage') ? true : false;
    const dynamicFrames = get(data, 'assetSetup.settings.dynamicFrames'); // Used get because we need to check if the value is false. And DataHelpers.getValue returns undefined if the value is false.
    const backgroundImageMultiplier = DataHelpers.getValue(data, 'assetSetup.settings.backgroundImageMultiplier');
    let uploadByLanguage = DataHelpers.getValue(data, 'assetSetup.settings.uploadByLanguage') ? true : false;
    const assetData = EditorData.getValueFromModel(dataModel + '.data.' + subsetActive + '.assetData');
    const assetUpdateModel = dataModel + '.data.' + subsetActive + '.assetData';
    let template = {};

    // Designed display ad
    let bottomSpacing = 0;

    if (data.type === 'displayAdDesigned') {
        template = Templates.get(data.type, data.assetSetup.templateIdentifier, 'templateExport');
        if (template) {
            url = process.env.TEMPLATES_URL;
            if (template?.enableAnimations) {
                bottomSpacing = 26;
            }

            // if the template type is displayAdDesigned (TD template) we will get this setting from the template itself instead of the first value saved in the creative
            positionByLanguage = template.positionByLanguage;
            uploadByLanguage = template.uploadByLanguage;
        }

        if (!template || Object.keys(template).length === 0) {
            return [];
        }
    }

    // If templates are designed in the Template Designer, you can give select the option if an image should be the background image
    // If we find an image that has this setting, we collect the model of that image (because TD create random keys for each layer)
    // If that model is found, the user can crop the background image for each format seperately
    let backgroundModel;
    if (data.type === 'displayAdDesigned') {
        backgroundModel = TemplateHelpers.findBackgroundModelInAssetData(assetData);
        if (!!backgroundModel && !isPreview) canEditBackground = true;
    }

    // Set data
    return formatsData.map((formatItem, i) => {
        // Prepare data
        let iframeData = DataHelpers.clone(assetData, false);
        // Scale large formats
        const scale = GenericHelpers.getScale(
            'display',
            formatItem.format,
            formatItem.width,
            formatItem.height,
            maxWidthScale,
            maxHeightScale,
            formatsData.length,
            defaultFullSize
        );
        const displayWidth = formatItem.width * scale;
        const displayHeight = formatItem.height * scale + bottomSpacing;
        const backgroundSize =
            backgroundSizes && backgroundSizes[formatItem.format] ? backgroundSizes[formatItem.format] : { width: formatItem.width, height: formatItem.height };
        const currentCategoryKey = subsetActive + '_' + formatItem.format;
        const isActive =
            (viewType === 'comments' &&
                (!commentsCategoryKey || commentsCategoryKey === 'all' || commentsCategoryKey === 'general' || commentsCategoryKey === currentCategoryKey)) ||
            (viewType !== 'comments' && (!selectedFormats || selectedFormats.length === 0 || selectedFormats.includes(formatItem.format)));
        const assetUpdateModelFull = dataModel + '.data.' + subsetActive + '.assetData.overwrites.' + formatItem.format;

        if (frameNr > 0 && !isPreview) {
            iframeData = {
                ...iframeData,
                editmode: true,
                frameNr: frameNr,
                format: formatItem.format,
                language: language,
                market: editor.market,
                positionByLanguage: positionByLanguage,
                uploadByLanguage,
                template: template,
                scale: scale
            };
        } else {
            iframeData = {
                ...iframeData,
                editmode: dynamicFrames === false, // If dynamic frames are enabled, we need to disable editmode if we are not in a frame
                format: formatItem.format,
                language: language,
                market: editor.market,
                positionByLanguage: positionByLanguage,
                uploadByLanguage,
                template: template,
                scale: scale
            };
        }

        // Merge overwrites
        let hasOverwrites = false;
        if (assetData && assetData.overwrites && assetData.overwrites[formatItem.format] && !isEmpty(assetData.overwrites[formatItem.format])) {
            hasOverwrites = true;
            iframeData = merge(iframeData, assetData.overwrites[formatItem.format]);
        }

        return {
            key: 'displayAd' + i + '-' + subsetActive + '-' + formatItem.width + '-' + formatItem.height,
            className: 'creative-builder-item__subitem ' + (!isActive ? 'creative-builder-item__subitem--inactive' : ''),
            width: displayWidth,
            height: displayHeight,
            component: (
                <PreviewFrame
                    type="display"
                    showTemplateSize={props.showTemplateSize}
                    // Display
                    title={formatItem.title}
                    gridSize={gridSize}
                    showGrid={showGrid}
                    format={formatItem.format}
                    displayWidth={displayWidth}
                    displayHeight={displayHeight}
                    width={formatItem.width}
                    height={formatItem.height}
                    bottomSpacing={bottomSpacing}
                    scale={scale}
                    loadInView={formatsData.length > 5 ? loadInView : false}
                    onChangeScale={onChangeScale}
                    // Comments
                    viewType={viewType}
                    commentCount={commentsCountsByCategory[currentCategoryKey]}
                    commentsCategoryKey={commentsCategoryKey}
                    onCommentsOpen={onCommentsOpen}
                    // Data
                    url={url}
                    frameNr={frameNr}
                    data={iframeData}
                    // Overwrite options
                    canOverwrite={frameNr && frameNr > 0 ? true : false}
                    hasOverwrites={hasOverwrites}
                    assetUpdateModel={assetUpdateModel}
                    assetUpdateModelFull={assetUpdateModelFull}
                    onEditBackground={onEditBackground}
                    backgroundSize={backgroundSize}
                    canEditBackground={canEditBackground}
                    // Background model
                    backgroundModel={backgroundModel}
                    backgroundImageMultiplier={backgroundImageMultiplier}
                    // Selectable items
                    isSelected={selectedFormats.includes(formatItem.format)}
                    onSelectItem={() => onSelectFormat(formatItem.format)}
                    canSelect={!isPreview && isSelectable}
                    isPreview={isPreview}
                />
            )
        };
    });
}
