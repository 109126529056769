import React from 'react';
import EditorData from 'components/editor-data/EditorData';
import PreviewFrame from '../../PreviewFrame';
import GenericHelpers from './generic.js';

/**
 * Static assets
 * These are manually uploaded display ads, images, or videos
 */
export default function (props, onChangeScale, onRemoveItem) {
    const {
        subsetActive,
        dataModel,
        isPreview = true,
        commentsCountsByCategory = {},
        viewType,
        onCommentsOpen,
        commentsCategoryKey,
        maxWidthScale,
        maxHeightScale,
        defaultFullSize
    } = props;

    let itemData = EditorData.getValueFromModel(dataModel);

    let assetData = EditorData.getValueFromModel(dataModel + '.data.' + subsetActive + '.assetData');

    if (!assetData) assetData = {};
    if (!assetData.items) {
        assetData.items = [];
    }
    let items = assetData.items;

    // Temporary fix for bricks (the two if statements below), because the data is already in the props
    if (!itemData) itemData = props.data;

    if ((!items || items.length === 0) && props?.data?.data?.main?.assetData?.items) {
        items = props.data.data.main.assetData.items;
    }

    // Set data
    return items.map((item, i) => {
        if (item.type === 'audio') {
            item.width = 400;
            item.height = 100;
        }

        const formatKey = item.format;
        const title = item.title ? item.title : item.width + 'x' + item.height;
        const scale = GenericHelpers.getScale(itemData.type, formatKey, item.width, item.height, maxWidthScale, maxHeightScale, items.count, defaultFullSize);
        const displayWidth = item.width * scale;
        const displayHeight = item.height * scale;
        const currentCategoryKey = subsetActive + '_' + formatKey;
        const isActive =
            (viewType === 'comments' &&
                (!commentsCategoryKey || commentsCategoryKey === 'all' || commentsCategoryKey === 'general' || commentsCategoryKey === currentCategoryKey)) ||
            viewType !== 'comments';

        return {
            key: 'staticAsset-' + item.uuid + '-' + subsetActive + '-' + i,
            className: 'creative-builder-item__subitem ' + (!isActive ? 'creative-builder-item__subitem--inactive' : ''),
            width: displayWidth,
            height: displayHeight,
            component: (
                <PreviewFrame
                    type={itemData.type}
                    width={item.width}
                    height={item.height}
                    displayWidth={displayWidth}
                    displayHeight={displayHeight}
                    scale={scale}
                    onChangeScale={onChangeScale}
                    // Metadata
                    title={title}
                    url={item.previewUrl ? item.previewUrl : item.url}
                    assetType={itemData.type}
                    messages={item.messages}
                    uuid={item.uuid}
                    // Comments
                    viewType={viewType}
                    commentCount={commentsCountsByCategory[currentCategoryKey]}
                    onCommentsOpen={onCommentsOpen}
                    commentsCategoryKey={commentsCategoryKey}
                    onRemoveItem={onRemoveItem}
                    // Display
                    format={formatKey}
                    isPreview={isPreview}
                    canSelect={false}
                />
            )
        };
    });
}
