import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from 'components/ui-components-v2/Dialog';
import ListItem from 'components/ui-components-v2/ListItem';
import List from 'components/ui-components-v2/List';
import '../styles/warnings.scss';

/**
 * Shows warnings for a format if applicable
 */
class PreviewFrameWarnings extends React.Component {
    static propTypes = {
        /** Width of the preview */
        warnings: PropTypes.array
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { open } = this.state;
        const { messages } = this.props;

        return (
            <React.Fragment>
                {messages && messages.length > 0 && (
                    <div className="preview-frame__warnings" onClick={this.handleOpen}>
                        <Icon fontSize="small">report_problem</Icon>
                    </div>
                )}

                {open && (
                    <Dialog onClose={this.handleClose} open={true}>
                        <DialogTitle>Warnings</DialogTitle>
                        <List>
                            {messages.map((message, i) => (
                                <ListItem key={'message' + i}>{message.message}</ListItem>
                            ))}
                        </List>
                    </Dialog>
                )}
            </React.Fragment>
        );
    }
}

export default PreviewFrameWarnings;
