import React from 'react';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import EditorData from 'components/editor-data/EditorData';
import Templates from 'components/data/Templates';
import DataHelpers from 'components/data/DataHelpers';
import PreviewFrame from '../../PreviewFrame';
import GenericHelpers from './generic.js';

/**
 * Creates the dynamic video (affectivity) items to use in the preview
 */
export default function (props, onChangeScale, onSelectFormat) {
    const {
        data,
        subsetActive,
        dataModel,
        language,
        isPreview = true,
        selectedFormats = [],
        loadInView = false,
        specificFormat,
        viewType,
        commentsCountsByCategory = {},
        onCommentsOpen,
        commentsCategoryKey,
        maxWidthScale,
        maxHeightScale,
        defaultFullSize
    } = props;

    const previewDataModel = dataModel + '.data.' + subsetActive + '.videoPreviewData.' + language + '.';

    // Get data
    const assetSetup = DataHelpers.getValue(data, 'assetSetup');
    const template = Templates.get(data.type, assetSetup.templateIdentifier);

    if (!template || Object.keys(template).length === 0) {
        return [];
    }

    // Get formats
    const formatsData = GenericHelpers.getFormats(data, specificFormat);

    let isSelectable = true;

    if (data.assetSetup && data.assetSetup.settings && data.assetSetup.settings.availableFormats && data.assetSetup.settings.availableFormats.length < 2) {
        isSelectable = false;
    }

    return formatsData.map((formatItem, i) => {
        // Scale large formats
        const scale = GenericHelpers.getScale(
            data.type,
            formatItem.format,
            formatItem.width,
            formatItem.height,
            maxWidthScale,
            maxHeightScale,
            formatsData.length,
            defaultFullSize
        );
        const displayWidth = formatItem.width * scale;
        const displayHeight = formatItem.height * scale;
        const currentCategoryKey = subsetActive + '_' + formatItem.format;
        const isActive =
            (viewType === 'comments' &&
                (!commentsCategoryKey || commentsCategoryKey === 'all' || commentsCategoryKey === 'general' || commentsCategoryKey === currentCategoryKey)) ||
            (viewType !== 'comments' && (!selectedFormats || selectedFormats.length === 0 || selectedFormats.includes(formatItem.format)));
        const assetUpdateModelFull = dataModel + '.data.' + subsetActive + '.assetData.overwrites.' + formatItem.format;

        // Merge overwrites
        const assetDataModel = dataModel + '.data.' + subsetActive + '.assetData';
        const assetData = EditorData.getValueFromModel(assetDataModel);
        let formatVideoData = DataHelpers.clone(assetData, false);
        const overwriteData = DataHelpers.getValue(assetData, 'overwrites.' + formatItem.format);
        let hasOverwrites = false;
        if (overwriteData && !isEmpty(overwriteData)) {
            hasOverwrites = true;
            formatVideoData = merge(formatVideoData, overwriteData);
        }

        return {
            key: 'video' + i + '_' + (formatItem.formatKey ? formatItem.formatKey : formatItem.format) + '_' + subsetActive + '_' + language,
            className: 'creative-builder-item__subitem ' + (!isActive ? 'creative-builder-item__subitem--inactive' : ''),
            width: displayWidth,
            height: displayHeight,
            component: (
                <PreviewFrame
                    type={data.type}
                    // Display
                    format={formatItem.format}
                    width={displayWidth}
                    height={displayHeight}
                    scale={scale}
                    title={formatItem.title}
                    loadInView={formatsData.length > 5 ? loadInView : false}
                    onChangeScale={onChangeScale}
                    // Comments
                    viewType={viewType}
                    commentCount={commentsCountsByCategory[currentCategoryKey]}
                    commentsCategoryKey={commentsCategoryKey}
                    onCommentsOpen={onCommentsOpen}
                    // Data
                    url={assetSetup.url ? assetSetup.url : assetSetup.previewUrl}
                    // Overwrite options
                    canOverwrite={true}
                    hasOverwrites={hasOverwrites}
                    assetUpdateModel={assetDataModel}
                    assetUpdateModelFull={assetUpdateModelFull}
                    // Video
                    videoData={{
                        data: formatVideoData,
                        mapping: template.publishMapping,
                        comp: formatItem.comp ? formatItem.comp : formatItem.format,
                        previewDataModel: previewDataModel + formatItem.format,
                        dataModel: assetDataModel,
                        controls: true,
                        autoPlay: false,
                        placeholderImage: template.image
                    }}
                    // Selectable items
                    isSelected={selectedFormats.includes(formatItem.format)}
                    onSelectItem={() => onSelectFormat(formatItem.format)}
                    canSelect={!isPreview && isSelectable}
                    isPreview={isPreview}
                    template={template}
                />
            )
        };
    });
}
